<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-card>
                <!-- :color="primaryColor" -->
                <v-toolbar dense flat color="#3F51B5" dark>
                    <v-toolbar-title>Sign in</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <template v-if="!isViewReady">
                    <v-card-text>
                        <p>Please wait...</p>
                    </v-card-text>
                </template>
                <template v-if="start && !redirect">
                    <v-card-text>
                        <p class="mb-0">
                            <v-progress-circular :color="primaryColor" indeterminate width="2" size="16" class="mr-2"></v-progress-circular>
                            Redirecting...
                        </p>
                    </v-card-text>
                </template>
                <template v-if="start && redirect">
                    <v-card-text>
                        <p><a :href="redirect">Continue to sign in</a></p>
                    </v-card-text>
                </template>
            </v-card>
            <template v-if="start && !redirect">
                <p class="mt-6 grey--text text--darken-2 text-center">Don't have an account yet? <router-link :to="{ name: 'signup' }">Sign up</router-link></p>
            </template>
            <p class="mb-15"></p>
            <v-alert type="error" v-if="serverError || loginshieldStartError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not process the request. Please try again. If the problem continues, please contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the information you entered is correct and try again.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { isEmpty } from '@/sdk/input';
// import { loginshieldInit } from '@loginshield/realm-client-browser';
// import { client } from '@/client';
// function loginshieldInit() {
//     return null; // TODO: we should make a loginfront sdk with iframe like loginshield, so it can be used without redirecting user to another site
// }

// TODO: use loginfront sdk to login, instead of having it built in here

export default {
    data: () => ({
        isViewReady: false,
        status: null, // could be 'loginshield'
        redirect: null, // only when server responds with a redirect url
        signupRequired: false,
        serverError: null,
        requestError: null,
        forbiddenError: null,
        submitTimeout: null,
        // errorTimeout: null,
        // all the attributes below are old and need to be reconsidered
        from: null,
        password: null,
        passwordError: false,
        loginshieldStartError: false,
        isRememberMeChecked: null,
    }),

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        start() {
            return isEmpty(this.$route.query.xentri_token);
        },
    },

    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async init() {
            // if user is returning from xentri, there will be a xentri_token in the url,
            // otherwise we automatically start a new sign in request
            const token = this.$route.query.xentri_token;
            if (token) {
                await this.checkLoginToken(token);
            } else {
                // await this.signup();
                await this.signin();
            }
            this.isViewReady = true;
        },
        async checkLoginToken(token) {
            try {
                this.$store.commit('loading', { checkLoginToken: true });
                // TODO: when loginfront is merged to loginshield, this query parameter will be renamed to loginshield_token; so we can remove the references to 'loginfront'
                if (token) {
                    const { isAuthenticated } = await this.$client.main().authn.checkLogin({ token });
                    if (isAuthenticated) {
                        await this.$store.dispatch('refresh');
                        this.redirectAfterLogin();
                        return;
                    }
                    this.$store.commit('setSession', { isAuthenticated: false });
                    this.$store.commit('setUser', {});
                }
            } catch (err) {
                console.error('start login failed', err);
                this.loginshieldStartError = true;
            } finally {
                this.$store.commit('loading', { checkLoginToken: false });
            }
        },
        resetErrors() {
            this.passwordError = false;
            this.loginshieldStartError = false;
        },
        async signin() {
            const now = Date.now();
            if (Number.isInteger(this.submitTimeout) && now - this.submitTimeout < 500) {
                return;
            }
            this.submitTimeout = now;
            try {
                this.resetErrors();
                this.$store.commit('loading', { signin: true });
                const { status, redirect } = await this.$client.main().authn.startLogin({});
                console.log(`Login.vue init redirect: ${redirect}`);
                if (['redirect'].includes(status) && redirect) {
                    // show redirect link while we try automatic redirect, with a brief delay to avoid flicker
                    setTimeout(() => {
                        this.status = status;
                        this.redirect = redirect;
                    }, 1000);
                    // use replace so that when user taps 'back' button from there, they won't
                    // end up being redirected again to where they just wanted to come back from
                    if (typeof window.location.replace === 'function') {
                        window.location.replace(redirect);
                    } else {
                        // TODO: also show link for user to click
                        window.location.href = redirect;
                    }
                } else {
                    this.status = status;
                }
            } finally {
                this.$store.commit('loading', { signin: false });
            }
        },
        async redirectAfterLogin({ nextInteractionId } = {}) {
            if (nextInteractionId) {
                const nextInteraction = await this.$store.dispatch('loadInteraction', nextInteractionId);
                console.log('redirectAfterLogin: next interaction: %o', nextInteraction);
                if (nextInteraction && nextInteraction.type) {
                    switch (nextInteraction.type) {
                    case 'require_login':
                        this.$router.push(nextInteraction.state.redirect);
                        return;
                    default:
                        this.$router.push({ path: '/dashboard', query: { i: nextInteractionId } });
                        return;
                    }
                }
            }
            if (this.next) {
                this.$router.push(this.next);
                return;
            }
            this.$router.push('/dashboard');
        },
    },

    mounted() {
        const rememberMeStored = localStorage.getItem('rememberMe');
        this.isRememberMeChecked = rememberMeStored === 'true';

        // if another view redirected here as part of a larger interaction like creating
        // an account, there should be an interaction id in the query
        this.interactionId = this.$route.query.i;

        // if another view redirected here with the intent of returning, it has to provide
        // the path and optional query parameters to redirect the user after login; the path
        // MUST be a relative path because this is not the right place for unrelated logic
        // about where we can redirect the user; the 'next' route could then redirect the
        // user to an offsite URL if needed
        const { next } = this.$route.query;
        if (typeof next === 'string' && next.startsWith('/')) {
            this.next = next;
        }
        console.log('Login.vue: mounted with isReady: %o and isAuthenticated: %o', this.isReady, this.isAuthenticated);
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
